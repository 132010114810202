import cx from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef } from 'react';

import { useControl } from './ControlProvider';
import type { FieldSize } from './types';

export interface InputProps extends Omit<ComponentPropsWithoutRef<'input'>, 'size'> {
  type: ComponentPropsWithoutRef<'input'>['type']; // make type explicit
  size?: FieldSize;
  variant?: 'default' | 'registration';
  autoComplete?:
    | 'given-name'
    | 'family-name'
    | 'email'
    | 'organization'
    | 'street-address'
    | 'postal-code'
    | 'address-level2'
    | 'current-password'
    | 'tel';
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function InputWithoutRef(
  { className, size = 'md', variant = 'default', autoComplete, ...rest },
  ref,
) {
  const control = useControl();
  return (
    <input
      ref={ref}
      autoComplete={autoComplete}
      className={cx(
        'bg-white border border-strong w-full ring-none focus-visible:outline-none focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-brand-blue/10 placeholder:text-inactive text-s-medium',
        [
          'hocus:before:hidden',
          variant === 'default' ? 'hocus:border-brand-blue rounded-lg' : 'hocus:border-brown-500 rounded-[10px]',
        ],
        {
          'focus-visible:!outline-red-500/10 !border-red-500 hocus:before:hidden': control?.error,
        },
        classesBySize[size],
        className,
      )}
      {...rest}
      {...control.inputProps}
    />
  );
});

const classesBySize: Record<FieldSize, string> = {
  sm: 'py-2 px-3',
  md: 'py-[0.6875rem] px-2.5',
  lg: 'py-4 px-5',
};
